<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form @keyup.enter="login">
                  <h4>Login</h4>
                  <p class="text-muted">Sign In to your account</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input v-model="params.login" type="text" class="form-control" placeholder="Login" autocomplete="off" />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-lock"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input v-model="params.password" type="password" class="form-control" placeholder="Password" autocomplete="new-password" />
                  </b-input-group>
                  <div v-if="error_message" v-html="error_message" class="alert alert-danger" role="alert"></div>
                  <div v-if="success_message" v-html="success_message" class="alert alert-success" role="alert"></div>
                  <b-row>
                    <b-col cols="6">
                      <b-button @click="login" variant="primary" class="px-4">Login</b-button>
                    </b-col>
                    <!-- <b-col cols="6" class="text-right">
                      <b-button variant="link" class="px-0">Forgot password?</b-button>
                    </b-col>-->
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <!-- <b-card no-body class="text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <b-card-body class="text-center">
                <div>
                  <h2>Sign up</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <b-button variant="primary" class="active mt-3">Register Now!</b-button>
                </div>
              </b-card-body>
            </b-card>-->
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Login',
  data() {
    return {
      error_message: null,
      success_message: null,

      params: {},

      logout_timeout: null,
    };
  },

  methods: {
    login() {
      this.$NProgress.start();
      this.error_message = null;
      this.success_message = null;

      let temp_params = Object.assign({}, this.params);

      temp_params = Object.assign(temp_params, { //TODO; this should be handled by Auth/Descriptor
        with_relationships: [
          /* {
            from_class_name: 'User',
            relationship_name: 'HasProfilePicture',
          }, */
          {
            from_class_name: 'Driver',
            relationship_name: 'WithUser',
          },
          {
            from_class_name: 'AssistantDriver',
            relationship_name: 'WithUser',
          },
          {
            from_class_name: 'Salesperson',
            relationship_name: 'WithUser',
          },
          {
            from_class_name: 'Collector',
            relationship_name: 'WithUser',
          },
          {
            from_class_name: 'Technician',
            relationship_name: 'WithUser',
          },
          {
            from_class_name: 'AccountManager',
            relationship_name: 'WithUser',
          },
          {
            from_class_name: 'CompanyMonitoring',
            relationship_name: 'WithUser',
          },
          {
            from_class_name: 'Administrator',
            relationship_name: 'WithUser',
          },
        ],
      });

      this.$api
        .post('/auth', temp_params)
        .then(response => {
          // success callback
          var data = this.$api.getData(response);
          if (data) {
            var access_token = data.token;
            var expires_at = data.expires_at;
            var user = data.user;

            if (user) {
              if (this.$d.getRelationship('User', 'HasProfilePicture', true)) {
                user.profile_picture = _.get(user, [this.$d.getRelationAliasByNames('User', 'HasProfilePicture'), 0, 'profile_picture']);
              }
            } else {
              user = {};
            }

            this.$auth.set(user, access_token, expires_at);
            this.success_message = 'Login success';
            setTimeout(() => {
              this.$router.push('/dashboard');
            }, 100);
          }
        })
        .catch(axios_error => {
          // error callback
          var error = this.$api.getError(axios_error);
          if (error) {
            this.error_message = this.$api.getValidation(error);
            if (error.type == 'authentication') {
              this.error_message = 'Login failed';
            }
          } else {
            console.error(axios_error);
          }
        })
        .then(() => {
          this.$NProgress.done();
          if (!this.success_message && !this.error_message) {
            this.error_message = this.$api.defaultErrorMessage;
          }
        });
    },

    logout() {
      this.$NProgress.start();
      this.error_message = null;
      this.success_message = null;

      this.$api
        .delete('/auth')
        .then(response => {
          // success callback
          var data = this.$api.getData(response);
          if (data) {
            if (data.logged_out) {
              this.success_message = 'Logged out successfully';
            }
          }

          this.$auth.destroy();
        })
        .catch(axios_error => {
          // error callback
          var error = this.$api.getError(axios_error);
          if (error) {
            if (error.type == 'authentication') {
              this.success_message = 'Already logged out';
            }
          }

          this.$auth.destroy();
        })
        .then(() => {
          this.$NProgress.done();
          if (!this.success_message && !this.error_message) {
            this.error_message = this.$api.defaultErrorMessage;
          }
        });
    },
  },

  created() {
    this.$auth.checkAuth().then(isAuth => {
      if (isAuth) {
        this.logout_timeout = setTimeout(() => {
          this.logout();
        }, 1000); //logout for real only if stays on page for more than 2 sec
      }
    });
    this.hello();
  },
  beforeDestroy() {
    clearTimeout(this.logout_timeout);
    this.logout_timeout = null;
  },
};
</script>
